<template>
  <div class="page">
    <div class="contact-us" id="message">
      <div class="title">{{ $t('common.onlineMsg.message') }}</div>
      <div style="width: 50vw;margin-left: -9vw">
        <ContactForm ref="form" lableWidth="18vw" :lang="lang"></ContactForm>
      </div>
      <div class="submit-button">
        <div class="btn flex row-center col-center" @click="handleSubmit">
          {{ $t('common.submit') }}
        </div>
      </div>
      <div round></div>
    </div>
    <div class="join-us" id="joinUs">
      <div class="title">{{ $t('contactUs.JoinUs') }}</div>
      <JoinUs :recruitList="recruitList"></JoinUs>
    </div>
    <div class="common-QA" id="commonQA">
      <div class="title">{{ $t('contactUs.commonQA') }}</div>
      <div v-for="(item, index) in questionList" :key="index">
        <div class="list">
          <div class="label">Q：{{ item.question }}</div>
          <div class="content">A：{{ item.answer }}</div>
        </div>
      </div>
    </div>
    <msg-dialog ref="successDialogVisible" />
  </div>
</template>

<script>
import JoinUs from '@/components/Common/JoinUs/index.vue'
import ContactForm from '@/components/Common/ContactForm/index.vue'
import { addDemand, getQuestionList, getRecruitList } from '@/api/contactUs'
import MsgDialog from '@/components/Common/Dialog/MsgDialog.vue'

export default {
  components: {
    MsgDialog,
    ContactForm,
    JoinUs
  },
  data() {
    return {
      lang: 'jpa',
      questionList: [],
      recruitList: []
    }
  },
  created() {
    this.lang = localStorage.getItem('language') || 'ja'
  },
  mounted() {
    this.getList(true)
  },
  watch: {
    '$i18n.locale'() {
      this.getList(false)
    }
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.getFormData()) {
        const params = this.$refs.form.getFormData()
        let specialServices = ''
        specialServices = this.$refs.form.getFormData().specialServices
        params.specialServices = specialServices.join(',')
        addDemand(params).then(res => {
          setTimeout(() => {
            this.$refs.form.reset()
            this.$refs.successDialogVisible.show()
          }, 1000)
        })
      }
    },
    getList(flag) {
      getQuestionList(this.lang).then(res => {
        this.questionList = res.data
        this.getRecruitList(true)
      })
    },
    getRecruitList() {
      getRecruitList(this.lang).then(res => {
        this.recruitList = res.data
        this.scroll()
      })
    },
    scroll() {
      if (this.$route.query.type == 1) {
        this.$nextTick(() => {
          let top = document.getElementById('message').offsetTop
          window.scrollTo({ top: top, behavior: 'smooth' })
        })
      } else if (this.$route.query.type == 2) {
        this.$nextTick(() => {
          let top = document.getElementById('joinUs').offsetTop
          window.scrollTo({ top: top, behavior: 'smooth' })
        })
      } else if (this.$route.query.type == 3) {
        this.$nextTick(() => {
          let top = document.getElementById('commonQA').offsetTop
          window.scrollTo({ top: top, behavior: 'smooth' })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  background-color: $bg-color;
  color: $light-color;
  width: 100%;
  padding-top: 72px;

  .contact-us {
    min-height: 2147px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .title {
      font-weight: bold;
      font-size: 80px;
      line-height: 117px;
      margin-bottom: 77px;
      margin-top: 78px;
    }
    .submit-button {
      margin-bottom: 130px;
      margin-top: 70px;
      .btn {
        width: 121px;
        height: 44px;
        border-radius: 980px;
        background: #bbb290;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        font-size: 17px;
        line-height: 25px;
        letter-spacing: 0.3px;
        margin: 90px auto 0;
        cursor: pointer;
      }
    }
  }
  .join-us {
    width: 100%;
    height: 983px;
    padding-top: 200px;
    box-sizing: border-box;
    .title {
      color: #86868b;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px; /* 107.143% */
      margin: 0 0 60px 330px;
    }
  }
  .common-QA {
    width: 100%;
    padding: 200px 330px 300px 330px;
    .title {
      color: #86868b;
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: 60px; /* 107.143% */
      margin-bottom: 40px;
    }
    .list {
      background-color: #000000;
      width: 1260px;
      box-sizing: border-box;
      padding: 44px 50px;
      border-radius: 18px;
      margin-bottom: 14px;
      .label {
        color: #f5f5f7;
        font-size: 26px;
        font-weight: 500;
        line-height: 35px; /* 125% */
        letter-spacing: 0.196px;
        margin-bottom: 17px;
      }
      .content {
        color: #f5f5f7;
        font-size: 22px;
        font-weight: 400;
        line-height: 25px; /* 147.059% */
      }
    }
  }
}
</style>
