<template>
  <div class="swiper-box">
    <div class="swiper joinUsSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide swiper-no-swiping" v-for="(item, index) in list" :key="index">
          {{ index }}
        </div>
      </div>
      <div class="custom-pagination flex row-center">
        {{ realIndex }}
        <div @click="pre" class="page" :class="realIndex === 0 ? 'disabled' : ''">上一页</div>
        <div @click="next" class="page" :class="realIndex === list.length - 3 ? 'disabled' : ''">
          下一页
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.min.css'

export default {
  data() {
    return {
      swiper: null,
      list: [{}, {}, {}, {}, {}, {}],
      realIndex: 0
    }
  },
  mounted() {},
  methods: {
    pre() {
      if (this.realIndex >= 1) {
        this.realIndex--
        this.left = -(Math.abs(this.left) - 22.916667)
        console.log('translate3d(' + this.left + 'vw, 0px, 0px)')
        document.querySelector('.swiper-wrapper').style.transform =
          'translate3d(' + this.left + 'vw, 0px, 0px)'
      }
    },
    next() {
      console.log(this.realIndex)
      if (this.list.length === 4 && this.realIndex === 1) {
        this.left = this.realIndex * 21.875 + 1.041667 * this.realIndex
        console.log('translate3d(-' + this.left + 'vw, 0px, 0px)')
        document.querySelector('.swiper-wrapper').style.transform =
          'translate3d(-' + this.left + 'vw, 0px, 0px)'
      } else {
        if (this.realIndex < this.list.length - 3) {
          this.realIndex++
          this.left = this.realIndex * 21.875 + 1.041667 * this.realIndex
          console.log('translate3d(-' + this.left + 'vw, 0px, 0px)')
          document.querySelector('.swiper-wrapper').style.transform =
            'translate3d(-' + this.left + 'vw, 0px, 0px)'
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.swiper-box {
  .swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .swiper-wrapper {
      margin-left: 330px;
      transition-property: transform;
      transition-duration: 200ms;
      .swiper-slide {
        width: 420px;
        height: 480px;
        background-color: aquamarine;
        margin-right: 20px;
      }
    }
    .custom-pagination {
      gap: 14px;
      .page {
        color: #ea5504;
        cursor: pointer;
      }
      .disabled {
        color: #4f4f4f;
        cursor: default;
      }
    }
  }
}
</style>
